import sortBy from "lodash/sortBy";

import PropTypes from "prop-types";

import React from "react";

import AnnotationTypeButtonComponent from "kt_jsgem/kt_editor/side_panel/annotation_type_button";

class AnnotationTypesPanelComponent extends React.Component {
  annotationTypes() {
    const isApplicableAnnotationType = (annotationType) =>
      !this.checkType() || (annotationType.checkType === this.checkType());

    return sortBy(
      Array.from(this.props.annotationTypes).filter(isApplicableAnnotationType),
      (annotationType) => annotationType.display.displayOrder);
  }

  checkType() {
    return this.props.checkType;
  }

  stateForAnnotationType(annotationTypeId) {
    return this.props.annotationTypesState.find((ats) => ats.id === annotationTypeId);
  }

  render() {
    return (
      <div id="kt-sidepanel-kt">
        <div className="categories-collection">
          {this.annotationTypes().map((annotationType) => {
            const annotationTypeState = this.stateForAnnotationType(annotationType.id);
            return (
              <AnnotationTypeButtonComponent
                annotationType={{
                  id: annotationType.id,
                    name: annotationType.display.name,
                    count: annotationTypeState.count || 0,
                    disabled: annotationTypeState.disabled || false,
                    color: annotationType.display.color,
                    markType: annotationType.display.markType
                }}
                key={annotationType.id}
                onAnnotationTypeClick={this.props.onAnnotationTypeClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

AnnotationTypesPanelComponent.propTypes = {
  annotationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      checkType: PropTypes.string.isRequired,
      display: PropTypes.shape({
        color: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        markType: PropTypes.string.isRequired
      }).isRequired,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  annotationTypesState: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      disabled: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  checkType: PropTypes.string,
  onAnnotationTypeClick: PropTypes.func.isRequired
};

export { AnnotationTypesPanelComponent as default, AnnotationTypeButtonComponent };
