import orderBy from "lodash/orderBy";

function getOffset(element) {
  const rect = element.getBoundingClientRect();
  const win = element.ownerDocument.defaultView;

  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset
  };
}

class AnnotationElementWrapper {
  constructor(annotationElement, annotations, responseAnnotationTypes, configAnnotationTypes) {
    const isAnnotationVisible = (annotation) => {
      const annotationType = responseAnnotationTypes.find((at) => at.id === annotation.typeId);

      return !(annotationType && annotationType.disabled);
    };
    const annotationMarkingOrder = (annotation) => {
      const annotationType = configAnnotationTypes.find((at) => at.id === annotation.typeId);
      if(!annotationType) {
        return Number.MAX_SAFE_INTEGER;
      }
      return annotationType.display.markingOrder;
    };

    const dataIds = annotationElement.dataset["ktid"];
    const annotationIds = dataIds ? dataIds.split(" ") : [];
    const allAnnotations = annotationIds.map((annotationId) => annotations.find((annotation) => annotation.id === annotationId));

    this.foundAnnotations = orderBy(allAnnotations, [annotationMarkingOrder]).filter(isAnnotationVisible);
    this.computedDimensions = AnnotationElementWrapper.computeDimensions(annotationElement);
  }

  annotation() {
    if(this.foundAnnotations.length) {
      return this.foundAnnotations[0];
    } else {
      return null;
    }
  }

  dimensions() {
    return this.computedDimensions;
  }

  static computeDimensions(annotationElement) {
    const offset = AnnotationElementWrapper.globalOffsetOfElement(annotationElement);
    return {
      top: offset.top,
      left: offset.left,
      height: annotationElement.offsetHeight,
      width: annotationElement.offsetWidth
    };
  }

  // Returns the position of an element in top-level document coordinates
  static globalOffsetOfElement(element) {
    let x = 0;
    let y = 0;
    let localWindow = element.ownerDocument.defaultView;

    let doLoop = true;
    while (doLoop) {
      const offset = getOffset(element);
      x += offset.left;
      y += offset.top;

      // Stop if the current element is in the main window
      if (localWindow === window) { doLoop = false; }

      if (doLoop) {
        // Correct for the scrolling of the local window
        x -= localWindow.pageXOffset;
        y -= localWindow.pageYOffset;

        // Jump one iframe up, using the iframe as the element
        // for the next iteration
        element = localWindow.frameElement;
        localWindow = localWindow.parent;
      }
      // Stop if we somehow couldn't find a valid element
      if (!element) { doLoop = false; }
    }

    return { left: x, top: y };
  }
}

export default AnnotationElementWrapper;
