import LegacyLoader from "kt_jsgem/lib/config/legacy_loader";
import KTService from "kt_jsgem/lib/kt_service";

import userFromInitialSettings from "kt_jsgem/lib/config/user_from_initial_settings";

class ConfigLoader {
  constructor(initialSettings) {
    this.initialSettings = initialSettings;
  }

  load(service = new KTService({}), language = this.initialSettings.defaultLanguage) {
    return service.getConfig({ configPath: this.initialSettings.paths.configPath, user: this.initialSettings.user }).then((config) => {
      return this.parseConfiguration(language, config);
    });
  }

  enableCheckTypesForConfiguration(checkTypes, serverConfiguration) {
    checkTypes.correctness = checkTypes.correctness && serverConfiguration.annotationCategories.find((ac) => ac.id === "correctness");
    checkTypes.readability = checkTypes.readability && serverConfiguration.annotationCategories.find((ac) => ac.id === "readability");
    checkTypes.correctnessFrisian = checkTypes.correctnessFrisian && serverConfiguration.annotationCategories.find((ac) => ac.id === "correctness-fries");
  }

  parseConfiguration(language, serverConfiguration) {
    this.enableCheckTypesForConfiguration(
      this.initialSettings.sidepanel.checkTypes, serverConfiguration
    );

    return {
      translations: this.initialSettings.translations,
      sidepanel: this.initialSettings.sidepanel,
      contextMenu: this.initialSettings.contextMenu,
      webserviceURL: this.initialSettings.paths.analysePath,
      addSuggestionURL: this.initialSettings.paths.addSuggestionPath,
      user: this.initialSettings.user,
      annotationTypes: serverConfiguration.annotationTypes,
      version: this.initialSettings.version,
      overLimit: serverConfiguration.overLimit,
      annotationCategories: serverConfiguration.annotationCategories,
      elearningEnabled: serverConfiguration.elearningEnabled,
      elearningURL: this.initialSettings.elearningURL,
      elearningModules: this.initialSettings.elearningModules,
      fetchFromLADURL: this.initialSettings.paths.fetchFromLADPath,
      documentName: this.initialSettings.documentName
    };
  }

  static initialSettingsFromLegacyConfig(legacyConfig) {
    const legacyLoader = new LegacyLoader(legacyConfig);
    const user = legacyConfig.user ? userFromInitialSettings(legacyConfig.user) : null;
    return {
      defaultLanguage: legacyLoader.defaultLanguage(),
      sidepanel: legacyLoader.parseSidepanel(),
      translations: legacyConfig.translations,
      user,
      paths: legacyLoader.paths(legacyLoader.defaultLanguage()),
      contextMenu: {
        ignoreButtons: (legacyConfig.contextMenu != null ? legacyConfig.contextMenu.ignoreButtons : undefined),
        showDescription: (legacyConfig.contextMenu != null ? legacyConfig.contextMenu.showDescription : undefined),
        addToPersonalList: (legacyConfig.contextMenu != null ? legacyConfig.contextMenu.addToPersonalList : undefined)
      },
      version: legacyConfig.version,
      elearningURL: legacyConfig.elearningURL,
      elearningModules: legacyConfig.elearningModules,
      documentName: legacyConfig.documentName
    };
  }
}

export default ConfigLoader;
