import LanguageLevel from "kt_jsgem/lib/language_level";
import LanguageLevelUtil from "kt_jsgem/lib/language_level_util";

const childTextValue = (parent, childTagName) => {
  const childElement = parent.getElementsByTagName(childTagName)[0];
  if(childElement) {
    return childElement.textContent;
  }else{
    return null;
  }
};

const filterLemmas = function(annotations) {
  return Array.from(annotations).
    filter((annotation) => annotation.typeId !== "LEMMA");
};

const Kt2 = {
  createConfigRequest(_options) { return ""; },

  parseConfigResponse(xml) {
    let checkType, xmlDisplay;
    const xmlAnnotationCategories = xml.getElementsByTagName("annotationCategories")[0];
    const annotationCategories = Array.from(xmlAnnotationCategories.getElementsByTagName("annotationCategory")).map((xmlAnnotationCategory) => {
      const minimumScoresMap = {};

      const minimumScoresTags = xmlAnnotationCategory.getElementsByTagName("minimumScores");
      if(minimumScoresTags.length) {
        Array.from(minimumScoresTags[0].children).forEach((minimumScore) => {
          minimumScoresMap[minimumScore.tagName] = Number.parseFloat(minimumScore.textContent);
        });
      }

      return {
        id: childTextValue(xmlAnnotationCategory, "id"),
        display: childTextValue(xmlAnnotationCategory, "display"),
        wordCategory: childTextValue(xmlAnnotationCategory, "wordCategory"),
        minimumScores: minimumScoresMap
      };
    });

    const annotationTypes = Array.from(xml.getElementsByTagName("annotationType")).map((xmlAnnotationType) =>
      ((checkType = childTextValue(xmlAnnotationType, "annotationCategory") || childTextValue(xmlAnnotationType, "type")),
      (xmlDisplay = xmlAnnotationType.getElementsByTagName("display")[0]),
      {
        checkType,
        id: childTextValue(xmlAnnotationType, "id"),
        addableWordCategory: childTextValue(xmlAnnotationType, "addableWordCategory") === "true",
        removableWordCategory: childTextValue(xmlAnnotationType, "removableWordCategory") === "true",
        display: {
          name: childTextValue(xmlDisplay, "name"),
          description: childTextValue(xmlDisplay, "description"),
          markType: childTextValue(xmlDisplay, "markType"),
          color: childTextValue(xmlDisplay, "color"),
          displayOrder: parseInt(childTextValue(xmlDisplay, "displayOrder")),
          markingOrder: parseInt(childTextValue(xmlDisplay, "markingOrder")),
          enabled: !(childTextValue(xmlDisplay, "enabled") === "false")
        }
      }));

    const overLimit = childTextValue(xml, "over-limit") === "true";
    const elearningEnabled = childTextValue(xml, "elearning-enabled") === "true";

    return {
      annotationCategories,
      annotationTypes,
      elearningEnabled,
      overLimit
    };
  },

  createXMLRequest(plainText, options) {
    if (options == null) { options = {}; }
    const filterIllegalXML = (xmlString) => {
      // Replace vertical tab '\v' by a regular newline
      xmlString = xmlString.replace("\v", "\n");
      // From xml spec valid chars:
      // #x9 | #xA | #xD | [#x20-#xD7FF] | [#xE000-#xFFFD] | [#x10000-#x10FFFF]
      // any Unicode character, excluding the surrogate blocks, FFFE, and FFFF.
      /* eslint-disable no-control-regex */
      xmlString = xmlString.replace(/[^\x09\x0A\x0D\x20-\uD7FF\uE000-\uFFFD\u10000-\u10FFFF]/g, " ");
      /* eslint-enable no-control-regex */
      return xmlString;
    };
    const createXMLFields = function(xml, fields) {
      if (fields == null) { fields = {}; }
      const fieldsXml = xml.createElement("fields");

      for (const key in fields) {
        const value = fields[key];
        const fieldXml = xml.createElement("field");

        const nameXml = xml.createElement("name");
        nameXml.appendChild(xml.createTextNode(key));

        const contentXml = xml.createElement("content");
        contentXml.appendChild(xml.createTextNode(filterIllegalXML(value)));

        fieldXml.appendChild(nameXml);
        fieldXml.appendChild(contentXml);
        fieldsXml.appendChild(fieldXml);
      }

      return fieldsXml;
    };
    const defaultOptions = { languageLevel: LanguageLevel.C1, checkType: "readability", documentName: "" };
    options = Object.assign({}, defaultOptions, options);

    const xml = window.document.implementation.createDocument("http://www.gridline.nl/1.0/docmodel/documents", "document", null);
    const root = xml.documentElement;

    const id = xml.createElement("id");
    id.appendChild(xml.createTextNode(options.documentName));
    root.appendChild(id);

    const source = xml.createElement("source");
    source.appendChild(xml.createTextNode("kt_jsgem"));
    root.appendChild(source);

    const fields = {
      "document-name": options.documentName,
      "content": plainText,
      "desired-level": LanguageLevelUtil.toString(options.languageLevel)
    };

    if(options.checkType) {
      fields["evaluation-goal"] = options.checkType;
    }

    root.appendChild(createXMLFields(xml, fields));

    return new window.XMLSerializer().serializeToString(xml);
  },

  parseXMLResponse(xml) {
    const fieldByName = function(xml, fieldName) {
      for (const xmlField of Array.from(xml.getElementsByTagName("field"))) {
        if (childTextValue(xmlField, "name") === fieldName) {
          return xmlField;
        }
      }
      return null;
    };

    const assignmentByType = function(xml, assignmentType) {
      for (const xmlAssignment of Array.from(xml.getElementsByTagName("assignment"))) {
        if (childTextValue(xmlAssignment, "type") === assignmentType) {
          return xmlAssignment;
        }
      }
      return null;
    };

    const xmlContentField = fieldByName(xml, "content");
    const content = childTextValue(xmlContentField, "content");

    let idIncrementer = 0;
    let annotations = Array.from(xmlContentField != null ? xmlContentField.getElementsByTagName("annotation") : []).map((xmlAnnotation) => {
      const id = `t${idIncrementer++}`;

      const start = window.parseInt(childTextValue(xmlAnnotation, "start"), 10);
      const end = window.parseInt(childTextValue(xmlAnnotation, "end"), 10);

      const type = childTextValue(xmlAnnotation, "type");

      const alternatives = Array.from(xmlAnnotation.getElementsByTagName("label")).map((xmlLabel) => (
        {
          alternative: childTextValue(xmlLabel, "name"),
          readOnly: false
        }
      ));

      const reason = childTextValue(xmlAnnotation, "reason");

      const annotation = {
        text: content.substring(start, end),
        start,
        end: end - 1,
        length: end - start,
        id,
        alternatives,
        reason,
        typeId: type
      };

      return annotation;
    });

    annotations = filterLemmas(annotations);

    const xmlAssignments = xml.querySelector("document > assignments");

    const xmlLevelLabelAssignment = assignmentByType(xmlAssignments, "level-label");
    let languageLevel = null;
    // Usually we'll have one label, which means we only run this loop once
    // If not, we'll take the last one. If we don't have any labels,
    // languageLevel stays null
    for (const xmlLabel of Array.from(xmlLevelLabelAssignment != null ? xmlLevelLabelAssignment.getElementsByTagName("label") : [])) {
      languageLevel = LanguageLevelUtil.fromUnsafeString(
        childTextValue(xmlLabel, "name"));
    }

    const xmlPropblemSizesAssignment = assignmentByType(xmlAssignments, "problem-sizes");
    const problemSizes = [];
    for (const xmlLabel of Array.from(xmlPropblemSizesAssignment != null ? xmlPropblemSizesAssignment.getElementsByTagName("label") : [])) {
      problemSizes.push({
        name: childTextValue(xmlLabel, "name"),
        score: parseFloat(childTextValue(xmlLabel, "score"))
      });
    }

    const xmlReadingTimeAssignment = assignmentByType(xmlAssignments, "reading-time");
    let readingTime = null;
    for(const xmlLabel of Array.from(xmlReadingTimeAssignment != null ? xmlReadingTimeAssignment.getElementsByTagName("label") : [])) {
      const value = childTextValue(xmlLabel, "name");
      if(value) {
        readingTime = parseFloat(value);
      }
    }

    const assignments = Array.from(xmlAssignments.getElementsByTagName("assignment")).map((xmlAssignment) => {
      return {
        type: childTextValue(xmlAssignment, "type"),
        labels: Array.from(xmlAssignment.getElementsByTagName("label")).map((xmlLabel) => {
          const scoreValue = childTextValue(xmlLabel, "score");
          return {
            name: childTextValue(xmlLabel, "name"),
            score: scoreValue != null ? parseFloat(childTextValue(xmlLabel, "score")) : null
          };
        })
      };
    });

    return {
      annotations,
      languageLevel: languageLevel ? LanguageLevel[languageLevel] : null,
      problemSizes: problemSizes,
      readingTime,
      assignments,
      content
    };
  },

  createAddSuggestionRequest(word, alternatives, { isAllowed = false, mainCategory = "readability", subCategory = "VAKTERM", wordInText = "" } = {}) {
    const namespace = "http://www.gridline.nl/1.0/kt-wlb/api";

    const xml = window.document.implementation.createDocument(namespace, "word", null);
    const root = xml.documentElement;

    const nameXml = xml.createElementNS(namespace, "name");
    nameXml.appendChild(xml.createTextNode(word));
    root.appendChild(nameXml);

    const mainCategoryXml = xml.createElementNS(namespace, "mainCategory");
    mainCategoryXml.appendChild(xml.createTextNode(mainCategory));
    root.appendChild(mainCategoryXml);

    const subCategoryXml = xml.createElementNS(namespace, "subCategory");
    subCategoryXml.appendChild(xml.createTextNode(subCategory));
    root.appendChild(subCategoryXml);

    const alternativesXml = xml.createElementNS(namespace, "alternatives");

    for (const alternative of Array.from(alternatives)) {
      const alternativeXml = xml.createElementNS(namespace, "alternative");
      alternativeXml.appendChild(xml.createTextNode(alternative));
      alternativesXml.appendChild(alternativeXml);
    }

    root.appendChild(alternativesXml);

    const isAllowedXml = xml.createElementNS(namespace, "isAllowed");
    isAllowedXml.appendChild(xml.createTextNode(isAllowed));
    root.appendChild(isAllowedXml);

    const wordInTextXml = xml.createElementNS(namespace, "wordInText");
    wordInTextXml.appendChild(xml.createTextNode(wordInText));
    root.appendChild(wordInTextXml);

    return new window.XMLSerializer().serializeToString(xml);
  },

  parseAddSuggestionResponse(_xml) {
    return true;
  },

  parseWordlist(xml) {
    const words = Array.from(xml.querySelectorAll("words word")).map((xmlWord) => {
      return {
        id: xmlWord.querySelector("id")?.textContent,
        name: xmlWord.querySelector("name")?.textContent,
        mainCategory: xmlWord.querySelector("mainCategory")?.textContent,
        subCategory: xmlWord.querySelector("subCategory")?.textContent,
        isAllowed: xmlWord.querySelector("isAllowed")?.textContent === "true"
      };
    });
    return words;
  }
};

export default Kt2;
