import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import nl from "date-fns/locale/nl";

const LastCheckSince = ({ now, lastCheckTimestamp, translations }) => {
  if (lastCheckTimestamp && lastCheckTimestamp instanceof Date) {
    return formatDistanceToNow(lastCheckTimestamp, {
      locale: nl,
      addSuffix: true
    });
  } else if (lastCheckTimestamp && typeof lastCheckTimestamp === "object" && lastCheckTimestamp._isAMomentObject) {
    return lastCheckTimestamp.clone().
      locale(translations.language || "nl").
      from(now);
  } else {
    return translations.notCheckedYet || "Nog niet gecontroleerd";
  }
};

LastCheckSince.propTypes = {
  translations: PropTypes.object.isRequired,
  lastCheckTimestamp: PropTypes.object,
  now: PropTypes.object
};

const LastCheckComponent = ({ lastCheckTimestamp, translations }) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000 * 10);
    return () => {
      clearInterval(interval);
    };
  }, [lastCheckTimestamp, setDate]);

  return (
    <div className="last-check-container">
      <span className="title">
        {translations.lastEvaluation || "Laatste evaluatie"}
      </span>
      <span className="last-check">
        <LastCheckSince lastCheckTimestamp={lastCheckTimestamp} translations={translations} now={date} />
      </span>
    </div>
  );
};

LastCheckComponent.propTypes = {
  translations: PropTypes.object.isRequired,
  lastCheckTimestamp: PropTypes.object
};

export { LastCheckComponent as default, LastCheckSince };
