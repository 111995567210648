import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const ReadingTimeComponent = ({ readingTime, lastCheckTimestamp }) => {
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    if(readingTime == null) {
      setHighlighted(false);
      return;
    }

    setHighlighted(true);
    const timeoutId = window.setTimeout(() => {
      setHighlighted(false);
    }, 2500);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [readingTime, lastCheckTimestamp]);

  const minutes = Math.floor(readingTime / 60);
  const seconds = Math.round(readingTime % 60);

  const duration = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

  const background = {
    backgroundColor: highlighted ? "#ffcd03" : "unset",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderRadius: "9999px"
  };

  const color = {
    color: highlighted ? "#000000" : "unset"
  };

  const scale = {
    transform: highlighted ? "scale(1.1)" : "unset",
    transformOrigin: "right"
  };

  const animations = {
    ...background,
    ...scale,
    ...color
  };

  const animated = {
    transitionProperty: "transform, color, background-color",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDuration: "500ms"
  };

  return (
    <div style={{ fontSize: "16px", marginTop: "10px", ...animated, ...animations }}>
      <div style={{ marinTop: "10px", fontWeight: "bold" }}>
        Leestijd
      </div>
      <div style={{ textAlign: "right", marginTop: "4px" }}>
        {duration}
      </div>
    </div>
  );
};

ReadingTimeComponent.propTypes = {
  readingTime: PropTypes.number,
  lastCheckTimestamp: PropTypes.object
};

export default ReadingTimeComponent;
