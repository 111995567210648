import React from "react";
import PropTypes from "prop-types";

class NotificationsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClose(this.props.id);
  }

  render() {
    const {
      type,
      notification
    } = this.props;

    return (
      <div className={`notifications-display -${type}`}>
        <p className="notification">{notification}</p>
        <div className="close" onClick={this.handleClick}>
          &times;
        </div>
      </div>
    );
  }
}

NotificationsDisplay.propTypes = {
  id: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  notification: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default NotificationsDisplay;
