import CKEDITOR from "ckeditor";

import Editor from "kt_jsgem/kt_editor/editor";

class Plugin {
  constructor(ktConfig, editor) {
    this.ktConfig = ktConfig;
    this.editor = editor;
    if (editor.config.extraConfig) { this.ktConfig.update(editor.config.extraConfig.KT); }
    this.loadExtraStylesheets(this.ktConfig.extraStylesheets);
    this.ktEditor = new Editor(this.ktConfig);

    // instanceReady is fired when the editor is loaded
    this.editor.on("instanceReady", () => {
      this.initializeSidebar(`[data-kt-side-panel-for="${this.editor.name}"]`);
      this.initializeTextElementAndMenu();
    });

    // contentDom is fired when the editor's contentDom is ready, this is also
    // fired when the contentDom is reset
    this.editor.on("contentDom", () => {
      this.ktEditor.setTextElement(this.editor.document.$.body);
      // Clear the annotations after a reset
      this.ktEditor.clearAnnotations();
    });

    this.editor.on("beforeDestroy", () => {
      this.ktEditor.clearAnnotations();
    });

    this.editor.dataProcessor.htmlFilter.addRules({
      elements: {
        span: (element) => {
          if(element.attributes["data-ktid"]) {
            delete element.name;
          }
          return element;
        }
      }
    });
  }

  initializeSidebar(sidebar = null){
    let sidebarElement = document.querySelector(sidebar);
    if(!sidebarElement) {
      // We don't have the sidebar placeholder we expected, fallback to
      // creating one ourselves
      sidebarElement = document.createElement("div");
      sidebarElement.style.float = "right";

      this.editor.container.$.prepend(sidebarElement);
    }
    this.ktEditor.setSidebarElement(sidebarElement);
    this.ktEditor.render();
  }

  initializeTextElementAndMenu() {
    this.ktEditor.setTextElement(this.editor.document.$.body);
    this.initKTEvents();
  }

  // This method should be usable if we cannot control the contentCss configuration
  // option in CKEditor. This method will add the specified stylesheets and
  // will register a callback to re-add the stylesheet if the content is reset.
  setContentCss(stylesheets) {
    const editor = this.editor;
    const addStylesheets = () => {
      editor.document.$.head.append(
        (Array.from(stylesheets).map((sheet) =>
          `<link href='${sheet}' rel='stylesheet' type='text/css'>`)
        ).join()
      );
    };

    this.editor.on("contentDom", () => {
      addStylesheets();
    });

    addStylesheets();
  }

  loadExtraStylesheets(stylesheets) {
    Array.from(stylesheets).map((stylesheet) =>
      CKEDITOR.document.appendStyleSheet(stylesheet)
    );
  }

  initKTEvents() {
    this.ktEditor.kt.on("checking", () => {
      this.toggleReadOnly(true);
    });

    this.ktEditor.kt.on("done", () => {
      this.toggleReadOnly(false);
    });

    this.ktEditor.kt.on("clear", () => {
      this.editor.container.removeClass("disabled");
    });
  }

  toggleReadOnly(disabled) {
    this.editor.container.$.classList.toggle("disabled", disabled);

    // We can only make one modification to CKEditor at a time,
    // see http://dev.ckeditor.com/ticket/10501
    setTimeout((() => {
      this.editor.setReadOnly(disabled);
    }), 0);
  }
}

export default Plugin;
