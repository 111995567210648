import React from "react";
import PropTypes from "prop-types";

class AnnotationTypeButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  annotationType() {
    return this.props.annotationType;
  }

  handleClick(event) {
    event.preventDefault();
    this.props.onAnnotationTypeClick(this.annotationType().id);
  }

  isUnderlineMarkType() {
    return this.annotationType().markType === "underline";
  }

  rectangleStyle() {
    const color = this.annotationType().disabled ? "#787878" : this.annotationType().color;

    if (this.isUnderlineMarkType()) {
      return { borderColor: color };
    } else {
      return { backgroundColor: color };
    }
  }

  render() {
    return (
      <button className={`category-block ${this.annotationType().disabled ? "-disabled" : ""}`}
        id={`category_${this.annotationType().id}`}
        onClick={this.handleClick}>
        <div className="category">
          <span className={`rectangle ${this.isUnderlineMarkType() ? "-underline" : ""}`}
            style={this.rectangleStyle()} />
          {this.annotationType().name}
        </div>
        <span className="annotations">
          {this.annotationType().count || 0}
        </span>
      </button>
    );
  }
}

AnnotationTypeButtonComponent.propTypes = {
  onAnnotationTypeClick: PropTypes.func.isRequired,
  annotationType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    markType: PropTypes.string.isRequired
  }).isRequired
};

export default AnnotationTypeButtonComponent;
